import React from "react"
import Layout from "../components/layout"
import { Grid, Container, Box } from "@mui/material"
import MediaCard from "../components/common/MediaCard"
import Seo from "../components/seo"

const rootStyle = {
  display: "flex",
  flexWrap: "wrap",
  padding: "30px",
  bgcolor: "white",
}

const ContactUS = () => {
  return (
    <>
      <Seo title="Docs" />
      <Layout>
        <Box sx={rootStyle}>
          <Container>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} md={4}>
                <MediaCard
                  image={require("../images/CircularNunguLogo.svg").default}
                  heading="Install Nungu Flow"
                  body="Learn how to install Nungu Flow for Windows, Mac and Linux"
                  link="/install/"
                ></MediaCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MediaCard
                  image={require("../images/GettingStarted.svg").default}
                  heading="Getting Started"
                  body="Learn how to use Nungu FLow and the library manager"
                  link="/getting-started/"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MediaCard
                  image={
                    require("../images/code-blocks/how_do_they_look.png")
                      .default
                  }
                  heading="Code Blocks"
                  body="A quick introduction to programming using Nungu Flow"
                  link="/code-blocks/"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MediaCard
                  image={require("../images/PID.svg").default}
                  heading="PID Controller"
                  body="Proportional, Integral and Derivative controller for water control"
                  link="/pid-controller/"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MediaCard
                  image={require("../images/face-recognition-1.png").default}
                  heading="Face Recognition"
                  body="Use face-recognition library to recognise and manipulate human faces"
                  link="/face-recognition/"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Layout>
    </>
  )
}

export default ContactUS
