import React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { CardActionArea } from "@mui/material"

export default props => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea href={props.link}>
        <CardMedia
          component="img"
          height="200"
          image={props.image}
          alt="Install Nungu Flow"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" align="center">
            {props.heading}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" align="center">
            {props.body}
          </Typography>
        </CardContent>
        {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      </CardActionArea>
    </Card>
  )
}
